<template>
	<screen size="small" class="congrats-view">
		<template slot="body">
			<div class="congrats-header">
				<div class="roundup-logo"></div>
			</div>
			<div class="congrats-content">
				<div class="congrats-icon"></div>
				<h3>
					We've updated our Terms of Service. Please view them
					<a
						target="_blank"
						href="https://roundupapp.com/platform-nonprofits-v2/"
						>here.</a
					>
				</h3>
				<ui-button @click="acceptTOS"
					>Continue to my dashboard</ui-button
				>
				<p>
					By continuing to your dashboard, you have accepted the new
					agreement.
				</p>
			</div>
		</template>
	</screen>
</template>

<script>
import Screen from '../../ui/Screen'
import { mapActions } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import UiButton from '../../ui/Button'
import UiLabel from '../../ui/Label'
import UiInput from '../../ui/Input'
import router from '../../../router'

export default {
	name: 'checkout-view',
	components: { UiButton, UiInput, UiLabel, Screen },
	data() {
		return {
			isSaving: false,
			merchant: cloneDeep(this.$store.state.merchant.item)
		}
	},
	methods: {
		...mapActions(['updateTOS']),
		acceptTOS() {
			const body = {
				id: this.institution.id,
				tos_accept: true,
				tos_accept_time: new Date()
					.toISOString()
					.replace('T', ' ')
					.replace('Z', ''),
				tos_accept_admin: this.$store.state.me.current.id
			}
			this.updateTOS(body)
				.then(() => {
					this.isSaving = false
					window.analytics.track('accepted_TOS')
					router.push({ name: 'verify' })
				})
				.catch(({ response }) => {
					this.isSaving = false
					this.$swal('Error', response.data.message)
				})

			return false
		}
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';

.congrats-view {
	padding: 60px 60px 40px 60px;
	text-align: left;
	.congrats-header {
		p {
			margin: 0px;
		}
	}
	.congrats-content {
		text-align: center;
		h3 {
			font-size: 24px;
			font-weight: 400;
		}
		.congrats-icon {
			background: url('../../../assets/congrats_icon.png');
			background-size: 100% 100%;
			width: 100px;
			height: 100px;
			display: inline-block;
		}
		p {
			font-size: 10px;
		}
	}
	.roundup-logo {
		background: url('../../../assets/roundup_logo.svg');
		background-size: 137px 14px;
		background-repeat: no-repeat;
		background-position: center;
		width: 100%;
		height: 14px;
		margin: 0px 0px 20px;
	}
	.accept {
		background: #000;
		padding: 10px;
		min-width: 90%;
		color: #fff;
		margin: auto;
	}
}
</style>
